import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

const forge = require('node-forge');


function CertificateRequestPage() {
  const user = useSelector((state) => state.auth.user);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCA, setSelectedCA] = useState('');
  const [selectedServer, setSelectedServer] = useState('');
  const [preValidationOrderNumber, setPreValidationOrderNumber] = useState('');
  const [csr, setCsr] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [csrValidationErrors, setCsrValidationErrors] = useState([]);
  const [dcvEmailAddress, setDcvEmailAddress] = useState('');
  const [csrInfo, setCsrInfo] = useState({}); 
  const [domainNames, setDomainNames] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isFailureDialogOpen, setIsFailureDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiUrl = `${process.env.REACT_APP_API_URL}/v1/provider/certificate/issue`;

  const products = [
    { id: 287, label: 'DV' },
    { id: 289, label: 'DV Wildcard' },
    { id: 279, label: 'DV Multi-domain' },
    { id: 7, label: 'OV' },
    { id: 492, label: 'OV Multi-domain' },
    {id: 35, label: 'OV WildCard'},
    { id: 556, label: 'EV' },
    { id: 557, label: 'EV Multi-domain' },
  ];

  const caCertificatesID = [
    { id: 4090, label: 'DV' },
    { id: 4091, label: 'OV' },
    { id: 4092, label: 'EV' }
  ];

  /* Atualize aqui os produtos compatíveis com preValidação */
  const productsWithPreValidation = [
    7,
    492,
    35,
    556,
    557
  ];

  const handleProductChange = (event) => {
    const newSelectedProduct = parseInt(event.target.value);
    setSelectedProduct(newSelectedProduct);
    setCsrValidationErrors([]);
    setPreValidationOrderNumber('');
  
    if ([287, 289, 279].includes(newSelectedProduct)) { // IDs de produtos DV
      const dvCA = caCertificatesID.find(ca => ca.label === 'DV');
      if (dvCA) setSelectedCA(dvCA.id);
    } else if ([7, 492, 35].includes(newSelectedProduct)) { // IDs de produtos OV
      const ovCA = caCertificatesID.find(ca => ca.label === 'OV');
      if (ovCA) setSelectedCA(ovCA.id);
    } else if ([556, 557].includes(newSelectedProduct)) { // IDs de produtos EV
      const evCA = caCertificatesID.find(ca => ca.label === 'EV');
      if (evCA) setSelectedCA(evCA.id);
    } else {
      setSelectedCA('');
    }
  };
  

  const servers = [
    { id: 2, label: "Apache" },
    { id: 10, label: "Java-based" },
    { id: 14, label: "Microsoft IIS 5.x to 6.x" },
    { id: 35, label: "Microsoft IIS 7.x and later" },
    { id: 36, label: "nginx" },
    { id: 18, label: "Oracle" },
    { id: 30, label: "Plesk" },
    { id: 31, label: "WHM/cPanel" },
    { id: -1, label: "OTHER "}
  ];

  const handleServerChange = (event) => {
    setSelectedServer(event.target.value);
  };

  const handleCsrChange = (event) => {
    const newCsr = event.target.value;
    setCsr(newCsr);
    validateCsrFields(newCsr);
  };

  const handleDomainNamesChange = (event) => {
    setDomainNames(event.target.value);
  };

  const [validationMessage, setValidationMessage] = useState('');

  const validateCsrFields = (csrText) => {
    const errors = [];
    let successMessage = '';
  
    try {
      const csr = forge.pki.certificationRequestFromPem(csrText);
  
      // Check for required fields
      if (!csr.subject.getField({ shortName: 'CN' })) {
        errors.push('Common Name (CN) is missing.');
      }
      if (!csr.subject.getField({ shortName: 'O' })) {
        errors.push('Organization (O) is missing.');
      }
      if (!csr.subject.getField({ shortName: 'L' })) {
        errors.push('Locality (L) is missing.');
      }
      if (!csr.subject.getField({ shortName: 'C' })) {
        errors.push('Country (C) is missing.');
      }
  
      // If there are no errors, set success message
      if (errors.length === 0) {
        successMessage = 'CSR content is correct.';
      }

      // Extrai informações do CSR
      const csrInfo = {
        commonName: csr.subject.getField({ shortName: 'CN' }).value,
        organizationName: csr.subject.getField({ shortName: 'O' }).value,
        locality: csr.subject.getField({ shortName: 'L' }).value,
        country: csr.subject.getField({ shortName: 'C' }).value,
      };
  
      console.log('CSR information:', csrInfo);
      setCsrInfo(csrInfo);
    } catch (error) {
      console.error('Error parsing CSR:', error);
      errors.push('Invalid CSR format');
    }
  
    // Set validation messages
    setCsrValidationErrors(errors);
    setValidationMessage(successMessage);
  };
  
  const renderPreValidationOrderNumberField = () => {
    if (productsWithPreValidation.includes(selectedProduct)) {
      return (
        <TextField
          fullWidth
          multiline
          rows={1}
          label="Pre Validation Order Number"
          variant="outlined"
          value={preValidationOrderNumber}
          onChange={(e) => setPreValidationOrderNumber(e.target.value)}
          sx={{ mt: 2 }}
        />
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (csrValidationErrors.length > 0) {
      console.error('Error trying to parse CSR:', csrValidationErrors);
      setLoading(false);
      return;
    }
  
    try {
      // Prepare the request body
      const requestBody = {
        customer_id: user.customer_id,
        product: selectedProduct,
        server: selectedServer,
        csr,
        app_rep_email_address: dcvEmailAddress,
        organization_name: csrInfo.organizationName,
        street_address_1: csrInfo.locality,
        joi_country_name: csrInfo.country,
        domain_names: domainNames,
        primary_domain_name: csrInfo.commonName,
        ca_certificate_id: selectedCA,
      };
  
      // Conditionally add pre_validation_order_number if it is filled out
      if (preValidationOrderNumber) {
        requestBody.pre_validation_order_number = preValidationOrderNumber;
      }
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        setIsFailureDialogOpen(true);
        console.error('API request error:', errorData.message);
        return;
      }
  
      const responseData = await response.json();
      setApiResponse(responseData);
      setIsSuccessDialogOpen(true);
    } catch (error) {
      setIsFailureDialogOpen(true);
      console.error('API request error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <>
      <Header />
      <Sidebar />
      <Box className='page-content'>
        <Typography variant="h4">Request your Certificate</Typography>

        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
          <InputLabel id="product-label">Select a Product type</InputLabel>
          <Select
            labelId="product-label"
            id="product-select"
            value={selectedProduct}
            label="Select a type"
            onChange={handleProductChange}
          >
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
          <InputLabel id="server-label">Select a Server type</InputLabel>
          <Select
            labelId="server-label"
            id="server-select"
            value={selectedServer}
            label="Select a Server Type"
            onChange={handleServerChange}
          >
            {servers.map((server) => (
              <MenuItem key={server.id} value={server.id}>
                {server.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Renderiza o campo pre_validation_order_number apenas para produtos específicos */}
        {renderPreValidationOrderNumberField()}
        
        {selectedProduct === 492 && ( // Mostra o campo "Domain Names" apenas para OV Multi-domain
          <TextField
            fullWidth
            multiline
            rows={1}
            label="Domain Names"
            variant="outlined"
            value={domainNames}
            onChange={handleDomainNamesChange}
            sx={{ mt: 2 }}
            helperText="Enter up to 3 domains separated by commas."
          />
        )}

        <TextField
          fullWidth
          multiline
          rows={5}
          label="Input a CSR"
          variant="outlined"
          value={csr}
          onChange={handleCsrChange}
          sx={{ mt: 2 }}
        />
        {csrValidationErrors.length > 0 && (
          <Box mt={2}>
            <Typography variant="h6">Validation Errors:</Typography>
            <ul>
              {csrValidationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Box>
        )}

        <TextField
          fullWidth
          multiline
          rows={1}
          label="Dcv Email Address"
          variant="outlined"
          value={dcvEmailAddress}
          onChange={(e) => setDcvEmailAddress(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Button type="button" variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>

        <Dialog open={isSuccessDialogOpen} onClose={() => setIsSuccessDialogOpen(false)}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            {apiResponse && (
              <Typography variant="body1">
                Certificate request successful! Order Number: {apiResponse.orderNumber}
              </Typography>
            )}
          </DialogContent>
        </Dialog>

        <Dialog open={isFailureDialogOpen} onClose={() => setIsFailureDialogOpen(false)}>
          <DialogTitle color='red' >Error</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Check the request fields and try again.
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default CertificateRequestPage;
