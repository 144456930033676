import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Divider,
  Tooltip,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';

import '../../styles.css';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import AddUserForm from './addUserForm';
import UAParser from 'ua-parser-js';

function UsersPage() {
  const user = useSelector((state) => state.auth.user);
  const [users, setUsers] = useState([]);
  const [sorting, setSorting] = useState({ field: '', order: 'asc' });
  const [isAddUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const fetchData = async () => {
    try {
      let customerId = user.customer_id;
      console.log('customer_id:', customerId);

      if (customerId !== undefined) {
        let apiUrl = `${process.env.REACT_APP_API_URL}/v1/user/${customerId}`;

        // Append sorting parameters to the API URL
        apiUrl += `?field=${sorting.field}&order=${sorting.order}`;

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const responseData = await response.json();

        if (Array.isArray(responseData)) {
          setUsers(responseData);
        } else {
          console.error('API did not return an array:', responseData);
        }
      } else {
        console.error('O usuário ou customer_id é inválido.');
      }
    } catch (error) {
      console.error('Erro ao buscar os usuários:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const parser = new UAParser();
    const userAgentInfo = parser.getResult();
    const logSuccessMessage = `Usuário ${user.username} acessou a página de Usuários` ||
      `Usuário não autenticado, redirecionando para a página de login. Navegador: ${userAgentInfo.browser.name}, Plataforma: ${userAgentInfo.os.name}`;
    console.log(logSuccessMessage);
  }, [user]);

  const handleAddUser = () => {
    setAddUserDialogOpen(true);
  };
  
  const handleEditUser = (userId) => {
    // Implemente a lógica para editar o usuário com o ID fornecido
    console.log(`Edit user id: ${userId}`);
  };

  const handleDeleteUser = (userId) => {
    // Implemente a lógica para excluir o usuário com o ID fornecido
    console.log(`Delete user id: ${userId}`);
  };

  const handleOpenAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const handleCloseAddUserDialog = () => {
    setAddUserDialogOpen(false);
  };
  
  const handleRefresh = () => {
    fetchData();
    setAddUserDialogOpen(false);
  };

  const sortData = (data, field, order) => {
    return data.slice().sort((a, b) => {
      if (order === 'asc') {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      } else {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        }
        return 0;
      }
    });
  };

  const handleSort = (field) => {
    if (sorting.field === field) {
      setSorting({ field, order: sorting.order === 'asc' ? 'desc' : 'asc' });
      const sortedData = sortData(users, field, sorting.order);
      setUsers(sortedData);
    } else {
      setSorting({ field, order: 'asc' });
      const sortedData = sortData(users, field, 'asc');
      setUsers(sortedData);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main>
        <div className='page-content'>
          <div className="header-buttons">
            <h2 className="userTitle">Manage Users</h2>
            <Button
              className="addUserButton"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenAddUserDialog}
            >
              Add User
            </Button>
            <Tooltip title="Refresh Data">
              <IconButton
                className="refreshButton"
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
          <TableContainer component={Paper} className="tableContainer">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tableCell"
                    onClick={() => handleSort('id')}
                  >
                    ID
                    {sorting.field === 'id' && (
                      sorting.order === 'asc' ? (
                        <ArrowUpwardIcon className="sortIcon" />
                      ) : (
                        <ArrowDownwardIcon className="sortIcon" />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    onClick={() => handleSort('name')}
                  >
                    Name
                    {sorting.field === 'name' && (
                      sorting.order === 'asc' ? (
                        <ArrowUpwardIcon className="sortIcon" />
                      ) : (
                        <ArrowDownwardIcon className="sortIcon" />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    onClick={() => handleSort('email')}
                  >
                    E-mail
                    {sorting.field === 'email' && (
                      sorting.order === 'asc' ? (
                        <ArrowUpwardIcon className="sortIcon" />
                      ) : (
                        <ArrowDownwardIcon className="sortIcon" />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    onClick={() => handleSort('phone')}
                  >
                    Phone
                    {sorting.field === 'phone' && (
                      sorting.order === 'asc' ? (
                        <ArrowUpwardIcon className="sortIcon" />
                      ) : (
                        <ArrowDownwardIcon className="sortIcon" />
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    onClick={() => handleSort('is_admin')}
                  >
                    Is Admin
                    {sorting.field === 'is_admin' && (
                      sorting.order === 'asc' ? (
                        <ArrowUpwardIcon className="sortIcon" />
                      ) : (
                        <ArrowDownwardIcon className="sortIcon" />
                      )
                    )}
                  </TableCell>
                  <TableCell className="tableCell">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="tableCell">{user.id}</TableCell>
                    <TableCell className="tableCell">{user.name}</TableCell>
                    <TableCell className="tableCell">{user.email}</TableCell>
                    <TableCell className="tableCell">{user.phone}</TableCell>
                    <TableCell className="tableCell">
                      <input type="checkbox" checked={ user.is_admin } disabled />
                    </TableCell>
                    <TableCell className="tableCell">
                      <IconButton
                        className="actionIcon"
                        onClick={() => handleEditUser(user.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className="actionIcon"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </main>
      <AddUserForm
        open={isAddUserDialogOpen}
        onClose={handleCloseAddUserDialog}
        onAddUser={handleAddUser}
      />
    </>
  );
}

export default UsersPage;
